import CssBaseline from "@mui/material/CssBaseline";
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
// Soft UI Dashboard PRO React themes
import theme from "assets/theme";
import Alert from "components/Alert";
// Soft UI Dashboard PRO React contexts
import { SoftUIControllerProvider } from "context";
import { NextIntlProvider } from "next-intl";
import Head from "next/head";
// react-router components
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { enGB, tr } from "date-fns/locale";
import { useRouter } from "next/router";
import { SnackbarProvider } from "notistack";
import { useEffect } from "react";
import { RecoilRoot } from "recoil";
import { AppApolloProvider } from "utils/ApolloProvider";
import initAuth, { logPage } from "utils/initAuth";

initAuth();

declare module "notistack" {
  interface VariantOverrides {
    alert: true;
  }
}

const getLocale = (lang: string | undefined) => {
  switch (lang) {
    case "en":
      return enGB;
    case "tr":
      return tr;
    default:
      return enGB;
  }
};

const MyApp = ({ Component, pageProps }) => {
  const { locale, pathname } = useRouter();
  const messages = require(`../lang/${locale}.json`);

  useEffect(() => {
    // Logging page view
    if (process.env.NODE_ENV === "production") {
      logPage(pathname);
    }

    // Setting page scroll to 0 when changing the route
    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop = 0;
      // document.scrollingElement.scrollTop = 0;
    }
  }, [pathname]);

  return (
    <RecoilRoot>
      <Head>
        <title>AVS BM</title>
      </Head>
      <NextIntlProvider
        messages={messages}
        now={new Date()}
        formats={{
          dateTime: {
            short: {
              day: "numeric",
              month: "short",
              year: "numeric",
            },
          },
        }}
      >
        <ThemeProvider theme={theme}>
          <SoftUIControllerProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getLocale(locale)}>
              <AppApolloProvider>
                <SnackbarProvider
                  maxSnack={5}
                  Components={{
                    alert: Alert,
                  }}
                  style={{
                    margin: 0,
                  }}
                  variant="alert"
                >
                  <CssBaseline />
                  <Component {...pageProps} />
                </SnackbarProvider>
              </AppApolloProvider>
            </LocalizationProvider>
          </SoftUIControllerProvider>
        </ThemeProvider>
      </NextIntlProvider>
    </RecoilRoot>
  );
};

export default MyApp;
