import { CancelTwoTone, InfoTwoTone, NotificationsTwoTone, TaskAltTwoTone, WarningTwoTone } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import SuiSnackbarAlert from "components/SuiSnackbarAlert";
import { SnackbarContent, useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { forwardRef, useCallback } from "react";

const Alert = forwardRef(({ id, message, color, ...props }, ref) => {
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  const renderIcon = (color) => {
    switch (color) {
      case "error":
        return <CancelTwoTone fontSize="small" color="white" />;
        break;
      case "success":
        return <TaskAltTwoTone fontSize="small" color="white" />;
        break;
      case "warning":
        return <WarningTwoTone fontSize="small" color="white" />;
        break;
      case "info":
        return <InfoTwoTone fontSize="small" color="white" />;
        break;
      default:
        return <NotificationsTwoTone fontSize="small" color="white" />;
        break;
    }
  };

  return (
    <SnackbarContent ref={ref}>
      <SuiSnackbarAlert
        onClose={handleDismiss}
        color={color}
        icon={
          <IconButton size="small" onClick={handleDismiss}>
            {renderIcon(color)}
          </IconButton>
        }
      >
        {message}
      </SuiSnackbarAlert>
    </SnackbarContent>
  );
});

// Setting default values for the props of SuiAlert
Alert.defaultProps = {
  color: "dark",
};

// Typechecking props of the SuiAlert
Alert.propTypes = {
  color: PropTypes.oneOf(["info", "success", "warning", "error", "dark"]),
  message: PropTypes.string.isRequired,
};

Alert.displayName = "Alert";

export default Alert;
