import { useAuthUser } from "next-firebase-auth";
import { atom, selector, useSetRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";
import getAbsoluteURL from "utils/getAbsoluteURL";

const { persistAtom } = recoilPersist();

export const atomToken = atom({
  key: "atomToken",
  default: null as string | null,
});

export const getTokenSelector = selector({
  key: "getTokenSelector",
  get: async ({ get }) => {
    const token = get(atomToken);
    if (token) {
      return token;
    }
    const AuthUser = useAuthUser();
    if (AuthUser) {
      // const setNamesState = useSetRecoilState(atomToken);
      const tokenAtom = await AuthUser.getIdToken();
      // setNamesState(tokenAtom);
      return tokenAtom;
    } else {
      return null;
    }
  },
});
export const currentMessageAtom = atom({
  key: "currentMessageAtom",
  default: "" as string,
});

export const congratsAtom = atom({
  key: "congratsAtom",
  default: "Sağlık, huzur ve başarı her daim sizinle olsun. Doğum gününüzü en içten dileklerimle kutlarım. İyi ki doğdun! 🎉",
});

export const jobCongratsAtom = atom({
  key: "jobCongratsAtom",
  default: "Her mükemmel sonuç, iyi yapılmış bir ekip çalışmasının sonucudur. Şirketimizin bu konuma yükselmesine yardım ettiğin için teşekkürler! 👏",
});

export const maxChatMessageAtom = atom({
  key: "maxChatMessageAtom",
  default: 1000 as number,
  effects_UNSTABLE: [persistAtom],
});

export const typingUsersAtom = atom({
  key: "typingUsersAtom",
  default: [],
  // effects_UNSTABLE: [persistAtom],
});

export const activeConversationAtom = atom({
  key: "activeConversationAtom",
  default: null as string | null,
  // effects_UNSTABLE: [persistAtom],
});

export const currentConversationAtom = atom({
  key: "currentConversationAtom",
  default: {
    name: "",
    email: "",
  } as { name: string; email: string },
  effects_UNSTABLE: [persistAtom],
});

export const isHRAtom = atom({
  key: "isHRAtom",
  default: null as boolean | null,
});

export const isITAtom = atom({
  key: "isITAtom",
  default: null as boolean | null,
});

export const isVMAtom = atom({
  key: "isVMAtom",
  default: null as boolean | null,
});

export const isFMAtom = atom({
  key: "isFMAtom",
  default: null as boolean | null,
});

export const registeredTeamsSelector = selector({
  key: "registeredTeamsSelector",
  get: async ({ get }) => {
    const isHR = get(isHRAtom);
    const isIT = get(isITAtom);
    const isVM = get(isVMAtom);
    const isFM = get(isFMAtom);

    if (isHR == null || isIT == null || isVM == null || isFM == null) {
      // const token = get(waitForAll([getTokenSelector]));

      const token = get(getTokenSelector);
      const endpoint = getAbsoluteURL(`/api/management/majorTeams`);
      const response = await fetch(endpoint, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token ? `${token}` : "unauthenticated",
        },
      });
      const { isHR, isIT, isVM, isFM } = await response.json();

      return { isHR, isIT, isVM, isFM };
    } else {
      return { isHR, isIT, isVM, isFM };
    }
  },
});

export const notificationAtom = atom({
  key: "notificationAtom",
  default: [],
});

export const notificationsSelector = selector({
  key: "notificationsSelector",
  get: ({ get }) => {
    const notification = get(notificationAtom);
    return notification;
  },
  set: async ({ set, get }) => {
    setTimeout(async () => {
      const token = get(getTokenSelector);
      const endpoint = getAbsoluteURL(`/api/notifications`);
      const response = await fetch(endpoint, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token ? `${token}` : "unauthenticated",
        },
      });
      const newNotifications = await response.json();
      const newNotificationsLength = newNotifications.length;
      if (newNotificationsLength > 0) {
        set(notificationAtom, newNotifications);
      }
    }, 60000); // 1 minute
  },
});

const setRegisteredTeams = (isHR: boolean, isIT: boolean, isVM: boolean, isFM: boolean) => {
  useSetRecoilState(isHRAtom);
  useSetRecoilState(isITAtom);
  useSetRecoilState(isVMAtom);
  useSetRecoilState(isFMAtom);
};
