import { useEffect, useState } from "react";
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { useRecoilState } from "recoil";
import { atomToken } from "utils/atom";

export const AppApolloProvider = ({ children }) => {
  const [tokenMS, setTokenMS] = useRecoilState(atomToken);
  const [client, setClient] = useState(
    new ApolloClient({
      cache: new InMemoryCache(),
    })
  );

  useEffect(() => {
    const httpLink = new HttpLink({
      uri: "https://avs-pm-hasura.365tech.dev/v1/graphql",
      headers: {
        Authorization: tokenMS ? `Bearer ${tokenMS}` : "",
      },
    });

    const wsLink =
      typeof window === "undefined"
        ? null
        : new GraphQLWsLink(
            createClient({
              url: "wss://avs-pm-hasura.365tech.dev/v1/graphql",
              connectionParams: {
                headers: { Authorization: tokenMS ? `Bearer ${tokenMS}` : "" },
              },
            })
          );

    // The split function takes three parameters:
    //
    // * A function that's called for each operation to execute
    // * The Link to use for an operation if the function returns a "truthy" value
    // * The Link to use for an operation if the function returns a "falsy" value
    const splitLink =
      typeof window === "undefined"
        ? null
        : split(
            ({ query }) => {
              const definition = getMainDefinition(query);
              return (
                definition.kind === "OperationDefinition" &&
                definition.operation === "subscription"
              );
            },
            // @ts-ignore
            wsLink,
            httpLink
          );

    setClient(
      new ApolloClient({
        // @ts-ignore
        link: splitLink,
        cache: new InMemoryCache(),
      })
    );
  }, [tokenMS]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
